.leaflet-container {
  height: 100%;
  width: 100%;
}

.marker-cluster-small {
  background-color: #ff0000 !important;
}

.marker-cluster-small div {
  background-color: #cc0202 !important;
  color: #fff !important;
  border-radius: 100%;
}

p.goToStop {
  text-align: center;
  text-decoration: underline;
  cursor: pointer;
}

h3.stopName {
  text-align: center;
}